.content {
  background-color: #0a050a;
  color: white;
  padding-bottom: 56px;
  width: 100%;
  /* min-width: 1920px; */
}
.content div {
  box-sizing: border-box;
}
.top {
  /* background-image: url(https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210906/587bd2fe614e4626a6c5a8f58b71738a.png); */
  width: 100%;
  /* height: 924px; */
  background-size: 100% auto;
  background-repeat: no-repeat;
  /* padding-left: 19%;
  padding-right: 19%; */
}
.topImg {
  display: none;
  width: 100%;
  height: auto;
}
.topImg img {
  width: 100%;
  height: auto;
}
.header {
  height: 92px;
  width: 100%;
  margin: auto;
  background-size: 100% 100%;
  background-image: url(https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/a7da352b6afd40998233042ef062ed33.png);
}
.headerContent {
  width: 1176px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerContent img {
  width: 220px;
  height: 73px;
}
.headerContentTitle {
  font-size: 20px;
  width: 569px;
  display: flex;
  justify-content: space-between;
}
.headerContentTitle a {
  font-size: 20px;
  color: white;
  height: auto;
  width: auto;
}
.notice {
  background-image: url(https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210831/d0e83ce5b7484007a3a13423b293a2dc.png);
  width: 48.7%;
  height: 343px;
  background-size: 100% 100%;
  color: white;
  padding: 35px;
}
.top-content {
  width: 1180px;
  margin: auto;
  margin-top: 24.7%;
  display: flex;
  justify-content: space-between;
}

.noticeTitle {
  display: flex;
  justify-content: space-between;
}
.noticeTitle div:nth-child(1) {
  font-size: 24px;
}
.noticeTitle div:nth-child(2),
.storyMore {
  font-size: 16px;
  background-image: url(https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210902/048fa30f524d488595d03ca54ea73bed.png);
  width: 89px;
  height: 35px;
  background-size: 100% 100%;
  line-height: 35px;
  text-align: center;
}
.noticeTitle div:nth-child(2):hover,
.storyMore:hover,
.yIntroduce:hover,
.wIntroduce:hover,
.downLoadTitle:hover,
.storyText-div:hover,
.storyImgItem:hover,
.yIntroducePartImgItem:hover {
  cursor: pointer;
}
.noticeContent {
  margin-top: 41px;
  height: 192px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.noticeContentItem {
  font-size: 14px;
  height: 22px;
  display: flex;
  padding-bottom: 3px;
  justify-content: space-between;
}
.noticeContentItem:hover {
  cursor: pointer;
  border-bottom: 0.5px solid white;
}
.noticeContentItem div:nth-child(1) {
  width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.yIntroduce {
  width: 24.3%;
}
.wIntroduce {
  width: 25.6%;
}
.yIntroduce img {
  width: 100%;
  height: auto;
}
.wIntroduce img {
  width: 100%;
  height: auto;
}
.main {
  width: 1180px;
  margin: auto;
}
.downLoad {
  padding-left: 49px;
  padding-right: 49px;
  display: flex;
  justify-content: space-between;
  margin-top: 56px;
  position: relative;
}
.downLoadCodeW {
  left: 0;
  text-align: center;
  padding-top: 28px;
  height: 221px;
  top: 95px;
}
.downLoadCode,
.downLoadCodeW {
  background-image: url(https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210906/23d86795823740609708f2781fa171de.png);
  width: 184px;
  background-size: 100% 100%;
  position: absolute;
}
.downLoadCode {
  top: 63px;
  height: 279px;
  left: 1000px;
  padding: 39px 10px 31px 10px;
}
.downLoadCode img,
.downLoadCodeW img {
  width: 164px;
  height: 164px;
}
.downLoadTitle {
  background-image: url(https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210831/6aaa3d5c157b4004b7d0b006d72ed8a5.png);
  width: 40.4%;
  height: 91px;
  background-size: 100% 100%;
  line-height: 91px;
  color: white;
  font-size: 26px;
  text-align: center;
}
.downLoadCodeButton {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 11px;
}
.downLoadCodeButton div {
  width: 89px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background-size: 100% 100%;
}

.wu {
  margin-top: 100px;
}
.wuTitle {
  color: white;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}
.wuTitle span {
  color: #f3479d;
  margin-left: 10px;
}

.wuPart {
  margin-top: 70px;
  width: 100%;
  color: white;
  display: flex;
}
.partItem {
  width: 25%;
  height: 355px;
  background-size: 100% 100%;
}
.partItemIn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}
.partItem:hover .partItemIn {
  background-image: url(https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/1fe02367e8e84ca185780841a362b9f3.png);
  background-size: 100% 100%;
  cursor: pointer;
  /* animation: scaleDrew 1s ease-in-out; */
}

@keyframes scaleDrew {
  /* 定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称 */
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}
.partItem:hover .partItemIn-div3 {
  background-color: #0a050a;
}
.partItemIn img {
  width: 52px;
  height: 52px;
}
.partItemIn-div1 {
  margin-top: 21px;
}
.partItemIn-div2 {
  margin-top: 6px;
}
.partItemIn-div3 {
  font-size: 20px;
  margin-top: 25px;
  background-color: #f3479d;
  width: 92px;
  height: 28px;
  border-radius: 14px;
  text-align: center;
  line-height: 28px;
}
.storyActivity {
  margin-top: 139px;
  font-size: 26px;
  color: white;
  display: flex;
  justify-content: space-between;
}
.story {
  width: 49.3%;
  /* width: 582px; */
  position: relative;
}
.line {
  width: 77%;
  height: 1px;
  background-color: #3b373b;
  margin-left: 16px;
  margin-top: 17px;
}
.checkLine,
.checkLine4 {
  width: 67px;
  height: 9px;
  left: 16px;
  position: absolute;
  top: 54px;
}
.checkLine4 {
  top: 54px;
  left: 127px;
}
.storyText {
  margin-left: 16px;
  display: flex;
  justify-content: space-between;
}
.storyText-div {
  font-size: 20px;
  margin-top: 18px;
}
.storyText-div span:nth-child(1) {
  margin-right: 54px;
}
.storyType {
  color: #f3479d;
}
.storyMore {
  margin-top: 16px;
}
.storyImg {
  margin-top: 68px;
  display: flex;
  justify-content: space-between;
}
.storyImgItem {
  width: 49.3%;
}
.storyImgItem img {
  width: 100%;
}
.activity {
  width: 45.7%;
  /* width: 540px; */
}
.activity img:nth-child(2) {
  margin-bottom: 9px;
}
.activityTitle {
  margin-bottom: 31px;
}
.activity img {
  width: 100%;
  height: auto;
}
.yIntroducePart {
  margin-top: 80px;
  font-size: 36px;
  font-weight: bold;
}
.yIntroducePartImg {
  margin-top: 53px;
  display: flex;
  justify-content: space-between;
}
.yIntroducePartTitle {
  text-align: center;
}
.yIntroducePartTitle span {
  color: #f3479d;
}
.yIntroducePartImgItem {
  width: 24.5%;
  /* height: auto; */
}
.yIntroducePartImgItem img {
  width: 100%;
}

.attention {
  margin-top: 80px;
}
.attentionTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.attentionTitle img {
  width: 106px;
  height: 7px;
}
.attentionTitle span {
  margin-right: 8px;
  margin-left: 8px;
  font-size: 30px;
}
.attentionContent {
  margin-top: 56px;
  display: flex;
  justify-content: space-between;
}
.attentionW {
  background-image: url(https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210902/24fccb0009f94c10a6c69913b0e98863.png);
  width: 49.6%;
  height: 343px;
  background-size: 100% 100%;
  padding: 34px 32px 34px 46px;
  color: #333333;
  font-size: 24px;
}
.service {
  display: flex;
  align-items: center;
}
.attentionW img {
  width: 68px;
  height: 72px;
  margin-right: 5px;
}
.infoW {
  color: #000000;
  font-size: 20px;
  margin-top: 23px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.infoW span {
  color: #ff34a1;
}

.copyright {
  text-align: center;
  margin-top: 45px;
  color: #999999;
  font-size: 12px;
}

@media only screen and (max-width: 1200px) {
  .headerContent,
  .main {
    width: 900px;
  }
  .top-content {
    width: 900px;
    margin-top: 23%;
  }
  .notice {
    height: 262px;
    padding: 30px;
  }
  .noticeContent {
    margin-top: 22px;
    height: 164px;
  }
  .noticeContentItem div:nth-child(1) {
    width: 280px;
  }

  .downLoadTitle {
    height: 70px;
    line-height: 70px;
  }
  .downLoadCodeW {
    top: 73px;
  }
  .downLoadCode {
    left: 721px;
    top: 46px;
  }
  .partItem {
    height: 317px;
  }
  .storyImg {
    margin-top: 32px;
  }
}
@media only screen and (max-width: 910px) {
  .header,
  .top {
    background-image: none !important;
  }
  .headerContentTitle {
    display: none;
  }
  .headerContent {
    width: 100%;
    padding-left: 17px;
  }
  .header {
    height: 53px;
  }
  /* .headerContent {
    display: block;
  } */
  .headerContent img {
    width: 113px;
    height: auto;
  }
  .noticeContentItem:hover {
    border-bottom: none;
  }
  .topImg {
    display: block;
    margin-bottom: 16px;
  }
  .downLoadCodeW,
  .downLoadCode {
    display: none !important;
  }
  .top-content {
    /* margin-top: 16px; */
    width: 91%;
    margin: auto;
    flex-wrap: wrap;
  }

  .notice {
    width: 100%;
    padding: 16px;
  }
  .noticeTitle div:nth-child(1) {
    font-size: 16px;
    font-weight: bold;
  }
  .noticeTitle div:nth-child(2),
  .storyMore {
    font-size: 13px;
    width: 72px;
    height: 28px;
    line-height: 28px;
  }

  .noticeContent {
    margin-top: 16px;
  }
  .noticeContentItem div:nth-child(1) {
    width: 200px;
  }
  .yIntroduce {
    width: 47.5%;
    margin-top: 16px;
  }
  .wIntroduce {
    width: 50.2%;
    margin-top: 16px;
  }

  .main {
    width: 91%;
    margin: auto;
  }
  .downLoad {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 8px;
  }
  .downLoadTitle {
    margin-top: 16px;
    width: 95%;
    font-size: 17px;
    height: 59px;
    line-height: 59px;
  }
  .wu {
    margin-top: 32px;
  }
  .wuPart {
    flex-wrap: wrap;
    margin-top: 24px;
    justify-content: space-between;
  }
  .wuTitle {
    font-size: 20px;
  }
  .partItem:hover .partItemIn {
    background-image: none;
  }
  .partItem:hover .partItemIn-div3 {
    background-color: #f3479d;
  }
  .partItem {
    width: 49%;
    height: 210px;
    margin-top: 8px;
  }
  .partItemIn {
    font-size: 16px;
  }
  .partItemIn img {
    width: 38px;
    height: 37px;
  }
  .partItemIn-div3 {
    width: 64px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
  }
  .storyActivity {
    margin-top: 20px;
    font-size: 16px;
  }
  .story {
    width: 100%;
  }
  .storyImg {
    margin-top: 17px;
  }
  .line {
    margin-top: 9px;
  }
  .checkLine,
  .checkLine4 {
    top: 32px;
    width: 54px;
    height: 7px;
  }
  .checkLine4 {
    left: 104px;
  }
  .storyText-div {
    font-size: 14px;
  }
  .activity {
    display: none;
  }
  .yIntroducePart {
    margin-top: 32px;
    font-size: 22px;
  }
  .yIntroducePartImg {
    margin-top: 16px;
    flex-wrap: wrap;
  }
  .yIntroducePartImgItem {
    margin-top: 8px;
    width: 49%;
  }
  /* .yIntroducePartImg img {
    margin-top: 8px;
    width: 49%;
  } */
  .attention {
    margin-top: 32px;
  }
  .attentionContent {
    margin-top: 15px;
  }
  .attentionTitle span {
    font-size: 18px;
  }
  .attentionTitle img {
    width: 64px;
    height: 4px;
  }
  .attentionContent {
    flex-wrap: wrap;
  }
  .attentionW {
    width: 100%;
    margin-top: 8px;
    height: 189px;
    font-size: 13px;
    padding: 19px 13px 19px 13px;
  }
  .attentionW img {
    width: 38px;
    height: 40px;
    margin-left: 0;
  }
  .infoW {
    font-size: 12px;
    height: 99px;
    margin-top: 13px;
  }
}
