.content {
  background-color: #0a050a;
  color: white;
  padding-bottom: 56px;
  width: 100%;
  min-height: 100vh;
  /* min-width: 1920px; */
}
.content div {
  box-sizing: border-box;
}
.header {
  height: 92px;
  width: 100%;
  margin: auto;
  background-size: 100% 100%;
  background-image: url(https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/a7da352b6afd40998233042ef062ed33.png);
}
.headerContent {
  width: 1176px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logoImg {
  width: 220px;
  height: 73px;
}
.headerContentTitle {
  font-size: 20px;
  width: 569px;
  display: flex;
  justify-content: space-between;
}
.headerContentTitle a {
  font-size: 20px;
  color: white;
  height: auto;
  width: auto;
}
.top {
  /* background-image: url(https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210903/b2a1bf93f7384d1297f2ec726feeec13.png); */
  width: 100%;
  /* height: 542px; */
  background-size: 100% auto;
  background-repeat: no-repeat;
  text-align: center;
}
.titleImg {
  width: 229px;
  height: 117px;
  margin-top: 12%;
}
.empty {
  margin-top: 3.4%;
  height: 1px;
}
.main {
  /* width: 1180px;
  margin: auto; */
}
.currentPlace {
  width: 1180px;
  margin: auto;
  display: flex;
  height: 74px;
  padding-top: 22px;
}
.currentPlaceDiv1:hover,
.currentPlaceDiv2 div:hover,
.noticeItem:hover {
  cursor: pointer;
}
.currentPlaceDiv1 span:hover {
  border-bottom: 1px solid white;
}
.currentPlaceDiv1 span {
  border-bottom: 1px solid black;
}
.currentPlaceDiv1 {
  font-size: 16px;
  margin-right: 188px;
  display: flex;
  align-items: center;
}
.currentPlaceDiv1 img {
  width: 12px;
  height: 10px;
  margin-right: 11px;
}
.currentPlaceDiv2 {
  font-size: 22px;
  color: #fcf3fc;
  display: flex;
}
.currentPlaceDiv2 div {
  display: flex;
  height: 50px;
  justify-content: space-between;
  margin-right: 64px;
  flex-direction: column;
  align-items: center;
}

.currentPlaceDiv2 img {
  width: 23px;
  height: 8px;
}
.currentSpan {
  color: white;
  font-weight: bold;
}
.currentImg {
  display: block;
}
.noImg {
  display: none;
}

.noticeItemHover {
  width: 100%;
}
.noticeItem,
.noborder {
  display: flex;
  padding: 43px 0;
  height: 188px;
  width: 1180px;
  margin: auto;
}
.noticeItem {
  border-bottom: 1px solid #34302a;
}
.noticeItem:hover {
  border: none;
}
.noborder {
  border: none;
}
.noticeItemHover:hover {
  background-color: #232827;
}
.listLeft {
  margin-right: 31px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #f3479d;
}
.listLeft img {
  width: 52px;
  height: 52px;
}
.listDate {
  margin-top: 8px;
}
.listRight {
  font-size: 16px;
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden; */
  width: 1016px;
}
.listRight p {
  line-height: 2;
  height: 61px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.listTitle {
  font-size: 20px;
  color: #f3479d;
  margin-bottom: 9px;
}
.listContent {
  font-size: 16px;
  margin-top: 7px;
}
.headerPh,
.mainPh,
.detailPh {
  display: none;
}
/* 详情 */
.detailTitle {
  margin: auto;
  width: 1200px;
  height: 117px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #34302a;
  position: relative;
}
.detailContent {
  width: 1084px;
  margin: auto;
  margin-top: 56px;
}
.detailContent p {
  color: white !important;
  font-size: 16px !important;
}
.detailTitleLeft {
  position: absolute;
  left: 0;
}
.detailTitleLeft img {
  width: 41px;
  height: 41px;
}
.detailTitleRight {
  text-align: center;
  width: 1150px;
  margin-left: 9px;
}
.detailTitleRight div:nth-child(1) {
  font-size: 22px;
  color: #f3479d;
}
.detailTitleRight div:nth-child(2) {
  font-size: 16px;
  margin-top: 16px;
}
@media only screen and (max-width: 1200px) {
  .currentPlace,
  .headerContent,
  .detailTitle,
  .detailContent {
    width: 900px;
  }
  .noticeItem {
    width: 900px;
    padding: 20px;
    height: 148px;
  }
  .listRight {
    width: 736px;
  }
  .currentPlaceDiv1 {
    margin-right: 126px;
  }
  .titleImg {
    margin-top: 8%;
  }
  .empty {
    margin-top: 2.8%;
  }
}

@media only screen and (max-width: 910px) {
  .top,
  .main,
  .detail {
    display: none !important;
  }
  .headerPh,
  .mainPh,
  .detailPh {
    display: block;
  }
  .headerPh div,
  .mainPh {
    width: 91%;
    margin: auto;
  }
  .headerPh {
    width: 100%;
    background-color: #1b1a1e;
  }
  .headerPh div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 53px;
  }
  .headerPh .logoImg {
    width: 113px;
    height: 38px;
  }
  .homeImg {
    width: 24px;
    height: 24px;
  }
  .mainTitle {
    font-size: 16px;
    margin-top: 32px;
  }
  .mainBanner {
    display: flex;
    align-items: center;
    margin-top: 8px;
    background-color: #1a1518;
    border-radius: 8px;
    height: 46px;
    font-size: 14px;
  }
  .mainBanner div {
    width: 73px;
    height: 46px;
    line-height: 46px;
    text-align: center;
  }

  .current,
  .current1 {
    color: #f7479e;
    background-color: #312c2f;
  }
  .current1 {
    border-radius: 8px 0 0 8px;
  }
  .noticeItemPh {
    margin-top: 8px;
    background-color: #232827;
    width: 100%;
    height: 188px;
    border-radius: 8px;
    padding: 18px 8px;
  }
  .itemTop {
    display: flex;
    color: #f3479d;
    height: 42px;
  }
  .itemTop img {
    width: 42px;
    height: 42px;
    margin-right: 10px;
    margin-left: 9px;
  }
  .itemTopTitle {
    font-size: 14px;
    width: 252px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .itemTopDate {
    font-size: 11px;
    margin-top: 3px;
  }
  .itemBottom {
    font-size: 13px !important;
    margin-top: 11px;
  }
  .itemBottom p {
    height: 96px;
    -webkit-line-clamp: 3;
    line-height: 2.4;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: normal !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  /* 详情 */
  .detailPh {
    width: 91%;
    margin: auto;
  }
  .detailTitlePh {
    margin-top: 26px;
    position: relative;
  }
  .detailTitlePh img {
    position: absolute;
    width: 26px;
    height: 26px;
  }
  .detailTitlePh div {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .detailMainPh {
    margin-top: 37px;
    background-color: #232827;
    border-radius: 8px;
    padding: 16px 8px 40px 8px;
  }
  .detailMainPh img {
    width: 100%;
  }
  .detailMainTitlePh {
    color: #f3479d;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }
  .detailMainDatePh {
    color: #f3479d;
    font-size: 11px;
    margin-top: 6px;
    text-align: center;
  }
  .detailContentPh {
    margin-top: 10px;
  }
}
