.content {
  background-color: #0a050a;
  color: white;
  padding-bottom: 56px;
  width: 100%;
  min-height: 100vh;
  /* min-width: 1920px; */
}
.headerPh {
  display: none;
}
.content div {
  box-sizing: border-box;
}
.header {
  height: 92px;
  width: 100%;
  margin: auto;
  background-size: 100% 100%;
  background-image: url(https://nogoodluck.oss-cn-beijing.aliyuncs.com/file/20210905/a7da352b6afd40998233042ef062ed33.png);
}
.headerContent {
  width: 1176px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logoImg {
  width: 220px;
  height: 73px;
}
.headerContentTitle {
  font-size: 20px;
  width: 569px;
  display: flex;
  justify-content: space-between;
}
.headerContentTitle a {
  font-size: 20px;
  color: white;
  height: auto;
  width: auto;
}
.top {
  width: 100%;
  /* height: 542px; */
  background-size: 100% auto;
  background-repeat: no-repeat;
  text-align: center;
}
.titleImg {
  margin-top: 12%;
}
.empty {
  margin-top: 3.4%;
  height: 1px;
}
.titleImg img {
  width: 229px;
  height: 117px;
}
.detailTitle {
  margin: auto;
  width: 1200px;
  height: 117px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #34302a;
  position: relative;
}
.detailContent {
  width: 1084px;
  margin: auto;
  margin-top: 56px;
}
.detailContent p {
  color: white !important;
  font-size: 16px !important;
}
.detailContent p img {
  display: block;
  margin: auto;
}
.detailTitleLeft {
  position: absolute;
  left: 0;
}
.detailTitleLeft img {
  width: 41px;
  height: 41px;
}

.detailTitleRight {
  font-weight: bold;
  display: none;
  text-align: center;
  font-size: 16px;
  color: #f3479d;
}

@media only screen and (max-width: 1200px) {
  .detailTitle,
  .headerContent,
  .detailContent {
    width: 900px;
  }
  .titleImg {
    margin-top: 8%;
  }
  .empty {
    margin-top: 2.8%;
  }
}
@media only screen and (max-width: 910px) {
  .top {
    display: none !important;
  }
  .detailTitleRight {
    display: block;
  }
  .detailTitleLeft img {
    width: 26px;
    height: 26px;
  }
  .detailTitle {
    height: 90px;
  }
  .detailContent {
    background-color: #232827;
    padding: 16px 8px;
    border-radius: 8px;
    margin-top: 40px;
  }
  .detailContent img {
    width: 100%;
  }
  .headerPh {
    display: block;
    width: 100%;
    background-color: #1b1a1e;
  }
  .headerPh div {
    width: 91%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 53px;
  }
  .headerPh .logoImg {
    width: 113px;
    height: 38px;
  }
  .homeImg {
    width: 24px;
    height: 24px;
  }
  .detailTitle,
  .detailContent {
    width: 91%;
  }
}
